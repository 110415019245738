import React, { Component } from "react";
import UserHelper from "../services/UserHelper";
import FormGroup from "./FormGroup";
import TimeAgo from "react-timeago";
import { Link } from "react-router-dom";

export default class Post extends Component {
  render() {
    return (
      <Link
        to={`/post/${this.props.post.identifier}/${this.props.post.slug}`}
        className="my-5 p-5 bg-white rounded-xl shadow overflow-hidden flex flex-wrap md:flex-nowrap hover:shadow-lg"
      >
        <div className="ml-4 flex items-start flex-col flex-shrink-1">
          <div className="flex items-center">
            <div>
              <img
                className="rounded-full w-10 h-10"
                src={this.props.post.picture_url}
              ></img>
            </div>
            <div className="ml-4">
              <div className="text-lg font-medium text-gray-900 flex">
                {this.props.post.title}{" "}
                <span className="ml-2 rounded-full p-1 px-3 bg-green-100 text-green-500 text-sm flex items-center">
                  <img
                    src="https://shelter-cdn.nyc3.digitaloceanspaces.com/public/coin_logo%20x64.png"
                    className="h-4 mr-2"
                  ></img>
                  {this.props.post.bounty_amount}
                </span>
              </div>
              <div className="text-sm text-gray-500">
                posted by{" "}
                <Link
                  to={`/profile/${this.props.post.username}`}
                  className="hover:underline mr-1"
                >
                  {this.props.post.username}
                </Link>
                <TimeAgo date={this.props.post.created_at} />
              </div>
            </div>
          </div>

          <div className="my-5 hidden md:block">{this.props.post.body}</div>

          <div className="w-11/12 border-t border-grey-50 m-2"></div>

          <div className="flex items-center justify-items-center">
            <span className="rounded-full p-1 px-3 bg-green-100 text-green-500 text-sm flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                />
              </svg>
              Upvotes {this.props.post.upvote_count}
            </span>

            <span className="ml-2 rounded-full p-1 px-3 bg-green-100 text-green-500 text-sm flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>{" "}
              Submissions: {this.props.post.comment_count}
            </span>
            <span className="ml-2 rounded-full p-1 px-3 bg-yellow-100 text-yellow-500 text-sm flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Expires in
              <TimeAgo
                className="ml-1"
                date={this.props.post.created_at + 604800000}
              />
            </span>
          </div>
        </div>
      </Link>
    );
  }
}
