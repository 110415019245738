import axios from "axios";
import React, { Component } from "react";
import ShelterToaster from "../services/ShelterToaster";
import TopUserDonner from "./TopUserDonner";
import TopUserDonnerLoading from "./TopUserDonnerLoading";

export default class TopDonner extends Component {
  state = {
    top_donners: null,
  };

  componentDidMount() {
    axios
      .get("/donners")
      .then((response) => {
        this.setState({ top_donners: response.data });
      })
      .catch(() => {
        ShelterToaster.error("Something went wrong loading the top donners");
      });
  }

  render() {
    return (
      <div className="bg-white rounded-2xl shadow donner flex-shrink-0 sticky top-10 m-10 hidden lg:flex flex-col items-center">
        <p className="mt-4 bg-green-100 rounded-full w-1/2 text-center font-light text-green-500 flex flex-nowrap justify-center items-center">
          <img
            className="w-4 h-4 inline-block mr-1"
            src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/google/298/star_2b50.png"
          ></img>{" "}
          Top Donners
        </p>
        {this.state.top_donners === null
          ? Array(7).fill(<TopUserDonnerLoading></TopUserDonnerLoading>)
          : this.state.top_donners.map((donner) => (
              <TopUserDonner
                key={donner.username}
                donner={donner}
              ></TopUserDonner>
            ))}
      </div>
    );
  }
}
