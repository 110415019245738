import AuthHelper from "./AuthHelper";

class UserHelper {
  getProfilePicture() {
    return AuthHelper.getUser().profile_picture_url;
  }
  getUsername() {
    return (AuthHelper.getUser() && AuthHelper.getUser().username) || "";
  }
}

export default new UserHelper();
