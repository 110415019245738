import React from 'react'

export default function HorizontalLogo() {
    return (
        <img
        src="https://shelter-cdn.nyc3.cdn.digitaloceanspaces.com/email/shelter-logo.png"
        className="h-8 block mx-auto" alt="Shelter Humanity's Logo"
      />
    )
}
