import React, { Component } from "react";
import UserHelper from "./services/UserHelper";
import Editor, { createEditorStateWithText } from "@draft-js-plugins/editor";
import createHashtagPlugin from "@draft-js-plugins/hashtag";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import detectEthereumProvider from "@metamask/detect-provider";
import NavBar from "./components/NavBar";
import TopDonner from "./components/TopDonner";
import ShelterToaster from "./services/ShelterToaster";
import Web3 from "web3";
import Contracts from "./services/Contracts";
import axios from "axios";
import { Redirect } from "react-router-dom";

const hashtagPlugin = createHashtagPlugin();
const linkifyPlugin = createLinkifyPlugin();

const contracts = Contracts();

const plugins = [linkifyPlugin, hashtagPlugin];

export class CreatePost extends Component {
  state = {
    editorState: createEditorStateWithText(""),
    provider: null,
    chainId: null,
    currentAccount: null,
    accounts: null,
    web3: null,
    show_loader: false,
    formData: null,
  };

  componentDidMount() {
    document.body.classList.remove("grey-bg");
    detectEthereumProvider().then((response) => {
      this.setState({ provider: response }, () => {
        let chainId = window.ethereum.request({ method: "eth_chainId" });
        chainId.then((currentChainId) => {
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          this.setState({ chainId: currentChainId }, () => {
            if (parseInt(this.state.chainId) == 1) {
              window.ethereum
                .request({ method: "eth_accounts" })
                .then((accountData) => {
                  this.setState({ accounts: accountData });
                })
                .catch((err) => {
                  ShelterToaster.error("Something went wrong");
                });
            }
          });
        });
      });
    });
  }

  requestMetaMaskAccess = () => {
    this.setState({ accounts: null });
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((accounts) => {
        this.setState({ accounts: accounts, web3: new Web3(window.ethereum) });
      })
      .catch((err) => {
        if (err.code === 4001) {
          this.setState({ accounts: [] });
        } else {
          ShelterToaster.error("Something went wrong");
        }
      });
  };

  onChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  getChainNameById(chainId) {
    switch (parseInt(chainId)) {
      case 1:
        return "Ethereum Main Network (Mainnet)";
      case 3:
        return "Ropsten Test Network";
      case 4:
        return "Rinkeby Test Network";
      case 5:
        return "Goerli Test Network";
      case 42:
        return "Kovan Test Network";
      default:
        return "Unknown Network";
    }
  }

  loadBalance = () => {
    const shelterToken = new this.state.web3.eth.Contract(
      contracts.Shelter.abi,
      contracts.Shelter.address
    );
    const balance = shelterToken.methods
      .balanceOf(this.state.accounts[0])
      .call();
    balance.then((shelterTokenBalance) => {
      this.setState({ balance: shelterTokenBalance });
    });
  };

  walletConnector = () => {
    if (this.state.provider == null || !this.state.provider.isMetaMask) {
      return (
        <div className="flex flex-col justify-center items-center my-5 max-w-md mx-auto bg-white rounded-xl shadow-none overflow-hidden md:max-w-2xl">
          <img
            src="https://metamask.io/images/mm-logo.svg"
            className="mb-4"
          ></img>
          <a
            href="https://metamask.io/download.html"
            target="_blank"
            className="bg-green-400 uppercase rounded-full text-white p-4 hover:bg-green-600"
          >
            Install MetaMask
          </a>
        </div>
      );
    }

    if (!this.state.provider.isConnected) {
      return (
        <div className="flex flex-col justify-center items-center my-5 max-w-md mx-auto bg-white rounded-xl shadow-none overflow-hidden md:max-w-2xl">
          <a
            href="./"
            className="bg-green-400 uppercase rounded-full text-white p-4 hover:bg-green-600"
          >
            Reload page to connect to MetaMask
          </a>
        </div>
      );
    }

    if (parseInt(this.state.chainId) !== 1) {
      return (
        <div className="flex flex-col justify-center items-start my-5 max-w-md mx-auto bg-white rounded-xl shadow-none overflow-hidden md:max-w-2xl">
          <div className="bg-red-200 text-red-400 uppercase rounded-full text-sm px-4 py-2">
            {`Connected to ${this.getChainNameById(this.state.chainId)} ${
              this.state.chainId
            }.`}
          </div>
          <div className="bg-red-200 text-red-400 uppercase rounded-full text-sm px-4 py-2 mt-2">
            {`Please connect to ${this.getChainNameById(1)}!`}
          </div>
        </div>
      );
    }

    if (this.state.accounts == null) {
      return (
        <div className="flex flex-col justify-center items-start my-5 max-w-md mx-auto bg-white rounded-xl shadow-none overflow-hidden md:max-w-2xl">
          <p className="flex justify-center p-10 mt-24">
            <img src="/loader.gif" />
          </p>
        </div>
      );
    }

    if (this.state.accounts.length == 0) {
      return (
        <div className="flex flex-col justify-center items-center my-5 max-w-md mx-auto bg-white rounded-xl shadow-none overflow-hidden md:max-w-2xl">
          <img
            src="https://metamask.io/images/mm-logo.svg"
            className="mb-4"
          ></img>
          <button
            onClick={() => this.requestMetaMaskAccess()}
            className="bg-green-400 uppercase rounded-full text-white p-4 hover:bg-green-600"
          >
            Connect to My MetaMask
          </button>
        </div>
      );
    }

    if (this.state.web3 == null) {
      this.setState({ web3: new Web3(window.ethereum) }, () => {
        this.loadBalance();
      });
    }

    return this.loader();
  };

  avatarSection = () => {
    return (
      <div className="mr-4">
        <img
          className="flex-shrink-0 w-12 h-12 rounded-full"
          src={UserHelper.getProfilePicture()}
        />
      </div>
    );
  };

  onlySignificant(balance, decimal) {
    let balanceArr = balance.split("");
    while (decimal--) {
      balanceArr.pop();
    }
    return balanceArr.join("");
  }

  handleBountyAmount = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  fetchBountyApproval = (bountyAmount) => {
    this.setState({ show_loader: true });

    const shelterToken = new this.state.web3.eth.Contract(
      contracts.Shelter.abi,
      contracts.Shelter.address
    );

    const escrow = new this.state.web3.eth.Contract(
      contracts.Escrow.abi,
      contracts.Escrow.address
    );

    console.log(escrow);

    bountyAmount = bountyAmount.toString();

    bountyAmount = bountyAmount.padEnd(bountyAmount.length + 18, "0");

    const approveAddress = this.state.accounts[0];

    const approval = shelterToken.methods
      .approve(approveAddress, bountyAmount)
      .send({ from: approveAddress });
    approval
      .then((allowed) => {
        console.log(allowed);

        axios
          .post("/post/create", this.state.formData)
          .then((response) => {
            if (response.data.status == "success") {
              ShelterToaster.success("Your bid was created!");
              return <Redirect to={response.data.redirect} />;
            }
          })
          .catch(() => {
            ShelterToaster.error("Something went wrong");
          })
          .finally(() => {
            this.setState({ show_loader: false });
          });
      })
      .catch((err) => {
        ShelterToaster.error(
          "You must approve the amount of Shelter Tokens to bid!"
        );
        this.setState({ show_loader: false });
      });
  };

  handleBidSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData(e.currentTarget);

    const bountyAmount = Math.abs(parseInt(formData.get("bid_amount")));
    const balanceAmount = parseInt(
      this.onlySignificant(this.state.balance, 18)
    );

    if (bountyAmount > balanceAmount) {
      ShelterToaster.error("Insufficent shelter tokens. Buy some more!");
      return false;
    }

    if (formData.get("bid_heading").length < 6) {
      ShelterToaster.error("The bidding heading is too short!");
      return false;
    }

    if (formData.get("bid_content").length < 10) {
      ShelterToaster.error("The bidding content is too short!");
      return false;
    }

    this.setState({ formData: formData, show_loader: true });

    this.fetchBountyApproval(bountyAmount);

    return false;
  };

  loader = () => {
    return (
      <div className="flex flex-col justify-center items-start my-5 max-w-md mx-auto bg-white rounded-xl shadow-none overflow-hidden md:max-w-2xl">
        <p className="flex justify-center p-10 mt-24">
          <img src="/loader.gif" />
        </p>
      </div>
    );
  };

  createPost = () => {
    return (
      <form
        onSubmit={(e) => this.handleBidSubmit(e)}
        className="flex flex-col my-5 w-11/12 md:w-3/4 h-3/4 max-w-2xl mx-auto bg-white rounded-xl shadow overflow-hidden"
      >
        <div className="flex p-4 flex-wrap justify-center md:justify-items-start">
          {this.avatarSection()}
          <input
            type="text"
            className="mt-4 h-12 w-11/12 flex-grow px-2 outline-none text-xl border rounded-full border-green-50 placeholder-opacity-30 focus:ring-1 focus:ring-green-200 md:w-auto md:mt-0"
            placeholder="Bid Heading"
            name="bid_heading"
            defaultValue={
              this.state.formData && this.state.formData.get("bid_heading")
            }
            required
          ></input>
        </div>
        <div className="w-full p-2 mx-4">
          <textarea
            rows="5"
            className="p-2 outline-none w-11/12 text-xl border rounded-md border-green-50 placeholder-opacity-30 focus:ring-1 focus:ring-green-200"
            placeholder="Bid Details..."
            name="bid_content"
            defaultValue={
              this.state.formData && this.state.formData.get("bid_content")
            }
            required
          ></textarea>
        </div>
        <div className="mx-6 mb-4 flex flex-col md:block">
          <input
            onKeyPress={(e) => this.handleBountyAmount(e)}
            type="number"
            placeholder="Bid Amount"
            name="bid_amount"
            defaultValue={
              this.state.formData && this.state.formData.get("bid_amount")
            }
            className="w-11/12 outline-none p-2 text-xl rounded-md border border-green-50 placeholder-opacity-30 focus:ring-1 focus:ring-green-200 md:w-6/12"
            required
          ></input>
          <span className="mt-4 w-11/12 text-sm rounded-full ml-1 md:ml-2 p-1 px-4 bg-green-100 text-green-500 md:mt-0 md:w-auto">
            Available Balance: {this.onlySignificant(this.state.balance, 18)}
          </span>
        </div>

        <hr></hr>
        <button
          type="submit"
          className="text-white text-sm m-4 w-32 bg-green-400 p-2 uppercase rounded-full hover:bg-green-500"
        >
          Submit Bid
        </button>
      </form>
    );
  };

  render() {
    return (
      <React.Fragment>
        <NavBar></NavBar>
        <div className="mx-auto flex">
          {this.state.balance === null || this.state.balance === undefined
            ? this.walletConnector()
            : this.state.show_loader
            ? this.loader()
            : this.createPost()}
          <TopDonner></TopDonner>
        </div>
      </React.Fragment>
    );
  }
}

export default CreatePost;
