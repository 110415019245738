import React, { Component } from "react";
import Feed from "./components/Feed";
import NavBar from "./components/NavBar";
import TopDonner from "./components/TopDonner";
import UserHelper from "./services/UserHelper";

export class Home extends Component {
  componentDidMount() {
    document.body.classList.add("grey-bg");
  }

  render() {
    return (
      <React.Fragment>
        <NavBar></NavBar>
        <div className="mx-auto flex">
          <Feed></Feed>
          <TopDonner></TopDonner>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
